import React, {
  useEffect,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  SwipeableDrawer,
  Box,
} from '@mui/material';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import DoneIcon from '@mui/icons-material/DoneOutlineRounded';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchProducts,
  selectAllProducts,
  getProductsStatus,
} from '../../redux/productsSlice';
import{
  getDeleteProductStatus,
  resetDeleteProductStatus,
} from '../../redux/productSlice'
import { getPostProductStatus, resetPostProductStatus } from '../../redux/channelSlice'
import ProductCard from './ProductCard';
import Skeleton from './ProductListSkeleton';
import styles from './ProductList.module.css';
import useQueryParams from '../../hooks/useQueryParams';

function ProductList() {
  const products = useSelector(selectAllProducts);
  const productsStatus = useSelector(getProductsStatus);
  const postProductStatus = useSelector(getPostProductStatus);
  const deleteProductStatus = useSelector(getDeleteProductStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId } = useQueryParams();

  useEffect(() => {
    if (productsStatus === 'idle') {
      dispatch(fetchProducts(shopId));
    }
  }, [dispatch, productsStatus, shopId]);

  const filteredProducts = products;

  const navigateToOrders = () => {
    navigate(shopId ? `/orders?shopId=${shopId}` : '/orders');
  };

  const handlePostSuccessScreenClose = useCallback(async () => {
    dispatch(resetPostProductStatus());
  }, [dispatch]);

  const handleDeleteSuccessScreenClose = useCallback(async () => {
    dispatch(resetDeleteProductStatus());
  }, [dispatch]);

  const Puller = styled(Box)(() => ({
    width: 40,
    height: 6,
    backgroundColor: 'var(--tg-theme-hint-color)',
    borderRadius: 3,
    // position: 'absolute',
    top: 8,
    // left: 'calc(50% - 20px)',
  }));

  if (productsStatus === 'loading') {
    return <Skeleton />;
  }

if (!products || !products.length) {
  return (
    <div className={styles.productListContainer}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton onClick={navigateToOrders}>
            <TimelineRoundedIcon className={styles.iconButton} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Products not found</Typography>
    </div>
  );
}

  return (
    <div className={styles.productListContainer}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'var(--tg-theme-section-bg-color)', boxShadow: 'none', height: '56px' }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton onClick={navigateToOrders}>
            <TimelineRoundedIcon className={styles.iconButton} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.productList}>
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <SwipeableDrawer
        anchor="bottom"
        open={postProductStatus === 'succeeded'}
        onClose={handlePostSuccessScreenClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 26,
            borderTopRightRadius: 26,
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            border: '1px 0px 0px 0px',
            padding: '0px 0px 32px 0px',
          },
        }}
      >
        <div className={styles.pullerWrapper}>
          <Puller />
        </div>
        <div className={styles.successWrapper}>
          <DoneIcon className={styles.iconSuccess} style={{ fontSize: 40 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: 'var(--tg-theme-section-header-text-color)' }}
          >
            Product posted successfully
          </Typography>
        </div>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="bottom"
        open={deleteProductStatus === 'succeeded'}
        onClose={handleDeleteSuccessScreenClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 26,
            borderTopRightRadius: 26,
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            border: '1px 0px 0px 0px',
            padding: '0px 0px 32px 0px',
          },
        }}
      >
        <div className={styles.pullerWrapper}>
          <Puller />
        </div>
        <div className={styles.successWrapper}>
          <DoneIcon className={styles.iconSuccess} style={{ fontSize: 40 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: 'var(--tg-theme-section-header-text-color)' }}
          >
            Product deleted successfully
          </Typography>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default ProductList;
  